.main-menu-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: $xcolor--bg;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;

  &.main-menu-overlay--visible {
    z-index: 9999;
    visibility: visible;
    opacity: 1;

    .menu-list {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.menu-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  color: #FFF;
  background: $xcolor--bg;
  opacity: 0;
  transform: translateY(40px);
  transition: 0.4s;
  padding: 0 15px;
  @include breakpoint(tablet) {
    padding: 0;
    align-items: center;
    text-align: center;
  }

  ul {
    position: relative;
    &:after {
      background-origin: content-box;
      content: '';
      width: 70px;
      background: lighten($xcolor--accent, 10%);
      height: 3px;
      display: block;
      margin: 20px 0 10px;
      @include breakpoint(tablet) {
        margin: 20px auto 10px;
      }
    }
  }

  ul li {
    list-style: none;

    a {
      color: #FFF;
      text-decoration: none;
      text-transform: lowercase;
      transition: 100ms;

      &:hover, &:focus {
        color: lighten($xcolor--accent, 10%);
      }
    }
  }

  .menu-list__primary-item {
    font-weight: 500;
    a {
      font-size: 30px;
      line-height: 110%;
      letter-spacing: -1px;
      @include breakpoint(tablet) {
        font-size: 40px;
      }
      @include breakpoint(desktop) {
        font-size: 50px;
        letter-spacing: -2px;
      }
      @include breakpoint(wide) {
        font-size: 70px;
      }
    }
  }

  .menu-list__secondary-item {
    margin: 5px;
    a {
      font-size: 22px;
      line-height: 110%;
      @include breakpoint(desktop) {
        font-size: 28px;
      }
    }
  }
}