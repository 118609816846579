.panel-modal__wrapper {
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($xcolor--bg, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-modal__window {
  position: relative;
  background: $xcolor--white;
  max-width: 540px;
  width: 540px;
  padding: 55px;
}

.panel-modal__title {
  font-size: 36px;
  line-height: 150%;
  font-weight: bold;
  text-align: center;
}

.panel-modal__content {
  text-align: center;
  padding: 20px 0 50px 0;
  font-size: 16px;
}

.panel-modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-modal__button {
  margin: 0 10px;
}

.panel-modal__close-button {
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
}