// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains styles concerning the structural layout of the Core.
// -----------------------------------------------------------------------------

// GENERAL
// -----------------------------------------------------------------------------
.xsite-width {
  margin: 0 auto;
  width: 100%;
  max-width: $xsite__max-width;
  padding-left: $xsite__padding;
  padding-right: $xsite__padding;
  @include breakpoint(mobile){
    padding-left: $xsite__padding--mobile;
    padding-right: $xsite__padding--mobile;
  }
  @include breakpoint(tablet){
    padding-left: $xsite__padding--tablet;
    padding-right: $xsite__padding--tablet;
  }
  @include breakpoint(desktop){
    padding-left: $xsite__padding--desktop;
    padding-right: $xsite__padding--desktop;
  }
  @include breakpoint(wide){
    padding-left: $xsite__padding--wide;
    padding-right: $xsite__padding--wide;
  }
}


// BLOCKS & INLINE
// -----------------------------------------------------------------------------
.xf { @include display('xf'); }
.xb { @include display('xb'); }
.xi { @include display('xi'); }
.xif { @include display('xif'); }
.xib { @include display('xib'); }
.xab { @include absolute; }
.xabf { @include absolute(true); }


// TABLES
// -----------------------------------------------------------------------------
.xtable {
  display: table;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  opacity: 1;
}
.xtrow {
  display: table-row;
  width: 100%;
}
.xtcell {
  display: table-cell;
  vertical-align: top;
}
.xtauto {
  table-layout: auto;
}


// TEXT
// -----------------------------------------------------------------------------
.xleft { text-align: left; }
.xright { text-align: right; }
.xcenter { text-align: center; }


// VERTICAL ALIGNMENT
// -----------------------------------------------------------------------------
.xvc { @include vertical-align; }
.xvc--top { @include vertical-align('top'); }
.xvc--bottom { @include vertical-align('bottom'); }


// SPACERS
// -----------------------------------------------------------------------------
.xspacer{
  height: $xspacer;
  width: 100%;
  @include breakpoint(tablet) {
    height: $xspacer--tablet;
  }
  @include breakpoint(desktop) {
    height: $xspacer--desktop;
  }
  @include breakpoint(wide) {
    height: $xspacer--wide;
  }
}
.xspacer--small{
  height: $xspacer--small;
  @include breakpoint(tablet){
    height: $xspacer--small--tablet;
  }
  @include breakpoint(desktop){
    height: $xspacer--small--desktop;
  }
  @include breakpoint(wide) {
    height: $xspacer--small--wide;
  }
}
.xspacer--line{
  &:after, &:before{
    content: '';
    display: block;
    height: $xspacer--line;
    width: 100%;
  }
  &:before{
    border-bottom: 1px solid $xcolor--text;
  }
  @include breakpoint(tablet){
    &:after, &:before{
      content: '';
      display: block;
      height: $xspacer--line--tablet;
    }
  }
  @include breakpoint(desktop){
    &:after, &:before{
      content: '';
      display: block;
      height: $xspacer--line--desktop;
    }
  }
  @include breakpoint(wide){
    &:after, &:before{
      content: '';
      display: block;
      height: $xspacer--line--wide;
    }
  }
}
