.ui-datepicker {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background: $xcolor--white;
  border: 1px solid $xcolor--light-grey;
  border-top: solid 1px $xcolor--bg;
  width: 352px;
  margin-top: -1px;
  transition: border 0.1s;

  &:hover {
    border: solid 1px $xcolor--bg;
  }
}

.ui-datepicker a {
  text-decoration: none;
}

.ui-datepicker table {
  width: 100%;
}

.ui-datepicker-header {
  background: $xcolor--light-grey;
  font-weight: bold;
  line-height: 50px;
}

.ui-datepicker-title {
  text-align: center;
}


.ui-datepicker-prev, .ui-datepicker-next {
  position: absolute;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ui-datepicker-prev .ui-icon, .ui-datepicker-next .ui-icon {
  width: 20px;
  height: 16px;
  background-image: $xsprite;
  background-image: $xsprite--svg;
  transition: all 0.1s;
}

.ui-datepicker-prev .ui-icon {
  background-position-x: -240px;
  background-position-y: -79px;
}

.ui-datepicker-next .ui-icon {
  background-position-x: -270px;
  background-position-y: -79px;
}

.ui-datepicker-prev {
  left: 0;
  &:hover {
    .ui-icon {
      transform: translateX(-4px);
    }
  }
}

.ui-datepicker-next {
  right: 0;
  &:hover {
    .ui-icon {
      transform: translateX(4px);
    }
  }
}


.ui-datepicker th {
  text-transform: uppercase;
  padding: 5px 0;
  color: $xcolor--dark-grey;
}

.ui-datepicker tbody td {
  padding: 0;
}

.ui-datepicker tbody tr {
  border-bottom: 1px solid $xcolor--light-grey;
}
.ui-datepicker tbody tr:last-child {
  border-bottom: 0px;
}

.ui-datepicker td span, .ui-datepicker td a {
  display: inline-block;
  text-align: center;
  width: 50px;
  height: 34px;
  line-height: 34px;
  color: $xcolor--bg;
}
.ui-datepicker-calendar .ui-state-default {

}
.ui-datepicker-unselectable .ui-state-default {
  color: $xcolor--medium-grey;
}

.ui-datepicker-calendar .ui-state-hover,
.ui-datepicker-calendar .ui-state-active {
  background: $xcolor--accent;
  color: $xcolor--white;
}