// -----------------------------------------------------------------------------
// This file contains styles related to the typography.
// -----------------------------------------------------------------------------

.body-xcms {
  color: $xcolor--text;
  font-family: $xfontstack--default;
  font-size: $xfontsize;
  font-weight: auto;
  line-height: $xlineheight;
  @include breakpoint(tablet) {
    font-size: $xfontsize--tablet;
    line-height: $xlineheight--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xfontsize--desktop;
    line-height: $xlineheight--desktop;
  }
}

.xtitle--medium {
  line-height: 110%;
}

// Add custom styles
.xtext{
  a {
    color: $xcolor--accent;
  }
  ul{
    padding: 0;
    margin: 0;
  }
  ol{
    padding: 0;
    margin: 0;
  }
}

$fontweight--light: 300;
$fontweight--regular: 400;
$fontweight--bold: 700;
