.cms-launcher {
  display: flex;
  position: fixed;
  right: 25px;
  bottom: 25px;
  transform: translateX(100px);
  transition: all 0.3s;

  &.cms-launcher--visible {
    transform: translateX(0px);
  }

  .button {
    margin-left: 20px;
  }
}