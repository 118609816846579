// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains styles concerning the typography of the Core.
// -----------------------------------------------------------------------------

body {
  font-family: $xfontstack--default;
  font-size: $xfontsize;
  line-height: $xlineheight;
  @include breakpoint(tablet) {
    font-size: $xfontsize--tablet;
    line-height: $xlineheight--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xfontsize--desktop;
    line-height: $xlineheight--desktop;
  }
  @include breakpoint(wide) {
    font-size: $xfontsize--wide;
    line-height: $xlineheight--wide;
  }
}

strong {
  font-family: $xfontstack--bold;
}


.xtext{
  font-family: $xfont;
  font-size: $xfontsize;
  line-height: $xlineheight;
  font-weight: $xfontweight;
  @include breakpoint(tablet) {
    font-size: $xfontsize--tablet;
    line-height: $xlineheight--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xfontsize--desktop;
    line-height: $xlineheight--desktop;
  }
  @include breakpoint(wide) {
    font-size: $xfontsize--wide;
    line-height: $xlineheight--wide;
  }
}

.xtitle {
  font-weight: $xtitle__fontweight;
  line-height: $xlineheight;
  @include breakpoint(tablet) {
    line-height: $xlineheight--tablet;
  }
  @include breakpoint(desktop) {
    line-height: $xlineheight--desktop;
  }
  @include breakpoint(wide) {
    line-height: $xlineheight--wide;
  }
}

.xtitle--small{
  font-family: $xtitle__font--small;
  font-size: $xtitle__fontsize--small;
  @include breakpoint(tablet) {
    font-size: $xtitle__fontsize--small--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xtitle__fontsize--small--desktop;
  }
  @include breakpoint(wide) {
    font-size: $xtitle__fontsize--small--wide;
  }
}

.xtitle--medium{
  font-family: $xtitle__font--medium;
  font-size: $xtitle__fontsize--medium;
  @include breakpoint(tablet) {
    font-size: $xtitle__fontsize--medium--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xtitle__fontsize--medium--desktop;
  }
  @include breakpoint(wide) {
    font-size: $xtitle__fontsize--medium--wide;
  }
}

.xtitle--large{
  font-family: $xtitle__font--large;
  font-size: $xtitle__fontsize--large;
  @include breakpoint(tablet) {
    font-size: $xtitle__fontsize--large--tablet;
  }
  @include breakpoint(desktop) {
    font-size: $xtitle__fontsize--large--desktop;
  }
  @include breakpoint(wide) {
    font-size: $xtitle__fontsize--large--wide;
  }
}