@charset "UTF-8";

// 1. Configuration and helpers
@import
  url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'),
  'utils/variables',
  'utils/functions',
  'utils/mixins';

// 2. Vendors
@import
  './../common/vendor/skinn/core',
  'vendor/dropzone',
  'vendor/featherlight',
  'vendor/jquery-selectric',
  'vendor/medium-editor';

// 2.5 Vendor extensions
@import
  'vendor-extensions/dropzone',
  'vendor-extensions/featherlight',
  'vendor-extensions/medium-editor-theme',
  'vendor-extensions/jquery-ui-datepicker',
  'vendor-extensions/jquery-selectric',
  'vendor-extensions/jssortable';

@import
  'base/base',
  'base/fonts';

// (Temporarily store all CSS within an ID wrapper. This need to be refactored later!)
#boness {
  // 3. Base stuff
  @import
    'base/typography',
    'base/helpers';

  // 4. Layout-related sections
  @import
    'layout/grid',
    'layout/header',
    'layout/footer',
    'layout/sidebar',
    'layout/main';

  // 5. Components
  @import
    'components/accordion',
    'components/actionPane',
    'components/cms-launcher',
    'components/forms',
    'components/buttons',
    'components/dataList',
    'components/dataTable',
    'components/dataTableBig',
    'components/dataTablePreview',
    'components/topMenu',
    'components/panel',
    'components/panelModal',
    'components/mainMenu',
    'components/pager',
    'components/tabs',
    'components/assets',
    'components/toast',
    'components/imageListItem',
    'components/imageField',
    'components/languageSwitcher';

  // 6. Page-specific styles
  @import
    'pages/pages',
    'pages/front',
    'pages/data',
    'pages/login';

  // 7. Themes
  @import
    'themes/default';
}
