.toaster {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  right: 0;
  height: 50px;
  pointer-events: none;
}

.toast {
  height: 50px;
  width: 100%;
  background: linear-gradient(150deg, #93FF69 30%, #3DD015);
  text-align: center;
}

.toast-transition {
  overflow: hidden;
  transform: translateY(0);
  transition: all 225ms cubic-bezier(0.0, 0.0, 0.2, 1);
}
.toast-enter, .toast-leave {
  opacity: 0;
  transform: translateY(-200%);
  transition: all 225ms cubic-bezier(0.4, 0.0, 1, 1);
}

.toast__title {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 50px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  .icon {
    margin-top: -3px;
    vertical-align: middle;
    margin-right: 10px;
  }
}