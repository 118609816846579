// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// GENERAL
// -----------------------------------------------------------------------------
$xsite__max-width: 1180px !default;
$xtext__max-width: 1020px !default;

$xsite__padding: 15px;
$xsite__padding--mobile: 15px;
$xsite__padding--tablet: 15px;
$xsite__padding--desktop: 15px;
$xsite__padding--wide: 15px;

/// Relative or absolute URL where all assets are served from.
$xbase-url: '../../' !default;

$xsprite: url($xbase-url + 'images/boness/skinn_cms_sprite.png');
$xsprite--svg: url($xbase-url + 'images/boness/skinn_cms_sprite.svg');


// COLOURS
// -----------------------------------------------------------------------------
$xcolor--text: #222;
$xcolor--icon: #EFEFEF;

$xcolor--white: #FFFFFF;              //white
$xcolor--light-grey: #EAEAEA;         //lighter
$xcolor--faded-grey: #BBBBBB;         //light
$xcolor--medium-faded-grey: #777777;  //medium
$xcolor--medium-grey: #2B2B2B;        //dark
$xcolor--dark-grey: #151515;          //darker
$xcolor--bg: #000000;                 //black

$xcolor--accent: #2231e7;
$xcolor--accent-gradient-light: #4056FF;
$xcolor--accent-gradient-dark: #050ED0;

$xcolor--text-light: #EFEFEF;



// SPACERS
// -----------------------------------------------------------------------------
$xspacer: 20px;
$xspacer--tablet: 20px;
$xspacer--desktop: 20px;
$xspacer--wide: 20px;

$xspacer--small: 20px;
$xspacer--small--tablet: 20px;
$xspacer--small--desktop: 20px;
$xspacer--small--wide: 20px;

$xspacer--line: $xspacer/2;
$xspacer--line--tablet: 20px;
$xspacer--line--desktop: 20px;
$xspacer--line--wide: 20px;


// GRID
// -----------------------------------------------------------------------------
// Generally, you don't have to change these settings.
// Only do so if you really have to.
$columns: 12 !default;
$column-width: 81px !default;
$gutter-width: 18px !default;
$fixed-gutter-size: true !default;

// Panel
  $panel__header-height: 90px;

// Backoffice
$sideBar-width: 200px;
$mainMenu--active__triangleSize: 8px;

// Old grid settings
$xgutter: 20px;
$xgutter--tablet: 20px;
$xgutter--desktop: 20px;
$xgutter--wide: 20px;


// TYPOGRAPHY
// -----------------------------------------------------------------------------
// Font stacks
// (These stacks can be renamed if necessary.)
$xfontstack--light: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$xfontstack--default: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$xfontstack--medium: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$xfontstack--bold: 'Roboto', 'Helvetica Bold', 'Arial Bold', sans-serif !default;
$xfontstack--helveticaneue: 'HelveticaNeueMedium', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;
$xfontstack--helveticaneue-bold: 'HelveticaNeueBold', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

// Types of fonts
// (These variable names may not be changed!)

// Text
$xfont: $xfontstack--default;
$xfont--strong: $xfontstack--bold;
$xfontsize: 14px;
$xfontsize--tablet: 14px;
$xfontsize--desktop: 14px;
$xfontsize--wide: 14px;

$xlineheight: 100%;
$xlineheight--tablet: 100%;
$xlineheight--desktop: 100%;
$xlineheight--wide: 100%;

$xfontweight: normal;

// Titles
$xtitle__fontweight: normal;

$xtitle__font: $xfontstack--bold;
$xtitle__lineheight: 16px;
$xtitle__fontsize: 16px;
$xtitle__fontsize--tablet: 16px;
$xtitle__fontsize--desktop: 16px;
$xtitle__fontsize--wide: 16px;

$xtitle__font--small: $xfontstack--bold;
$xtitle__lineheight--small: 12px;
$xtitle__fontsize--small: 12px;
$xtitle__fontsize--small--tablet: 12px;
$xtitle__fontsize--small--desktop: 12px;
$xtitle__fontsize--small--wide: 12px;

$xtitle__font--medium: $xfontstack--bold;
$xtitle__lineheight--medium: 24px;
$xtitle__fontsize--medium: 24px;
$xtitle__fontsize--medium--tablet: 24px;
$xtitle__fontsize--medium--desktop: 24px;
$xtitle__fontsize--medium--wide: 24px;

$xtitle__font--large: $xfontstack--bold;
$xtitle__lineheight--large: 42px;
$xtitle__fontsize--large: 42px;
$xtitle__fontsize--large--tablet: 42px;
$xtitle__fontsize--large--desktop: 42px;
$xtitle__fontsize--large--wide: 42px;

// Buttons
$xbtn__fontsize: 16px;
$xbtn__fontsize--tablet: 16px;
$xbtn__fontsize--desktop: 16px;
$xbtn__fontsize--wide: 16px;
