.imagelist__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 120px;
  border-bottom: solid 1px $xcolor--light-grey;
}

.imagelist__preview-col {
  flex: 0 0 90px;

  .image-container {
    position: relative;

    .image {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: solid 1px $xcolor--light-grey;
      cursor: pointer;
      background-size: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: rgba(0,0,0,0.4);
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s;
    }

    .icon {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
}

.imagelist__info-col {
  flex-grow: 1;
  padding: 0 20px;
  min-width: 0; //https://css-tricks.com/flexbox-truncated-text/

  .name {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 4px;
  }

  .size {
    font-size: 14px;
    font-weight: 200;
    color: $xcolor--faded-grey;
    letter-spacing: 1px;
  }
}

.imagelist__actions-col {
  flex: 0 0 80px;

  .delete {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}