.sortable-chosen {
  border: none;
  opacity: 0.9;
}

.sortable-ghost {
  border: none;

  > div:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: dashed 2px $xcolor--light-grey;
    background: $xcolor--white;
    z-index: 2;
  }
}