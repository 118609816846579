.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone {
  min-height: 150px;
  background: #fff;
  border: 2px dashed #ddd;
  padding: 10px;
  transition: all 300ms ease;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  @include on-event {
    background: #fafafa;
  }
}

.dz-message {
  margin: 0;
  .medium {
    font-weight: 500;
  }
  .fa {
    color: #ccc;
    margin-bottom: 15px;
  }
}

.dz-preview {
  .dz-image {
    border-radius: 0;
  }

  &:hover .dz-image {
    filter: blur(4px);
  }

  .dz-progress {
    border-radius: 0;
  }
}