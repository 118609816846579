// UploadField
.upload-wrapper {
  margin-bottom: 30px;
}

// Selected Assets
.assets-subtitle {
  margin-bottom: 15px;
  font-weight: 500;
}
.selectedAsset {
  transition: all 100ms ease;
  user-select: none;
  @include on-event {
    cursor: pointer;
    background: #e6e6e6;
  }
  & + .selectedAsset {
    margin-top: 5px;
  }
  .name {
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .size,
  .filesize {
    font-style: italic;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.selectedAsset__preview {
  .image {
    max-height: 80px;
    @include breakpoint(desktop) {
      max-height: none;
    }
  }
}
.selectedAsset__info {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

// ImageTile
.imageTile-wrapper {
  transition: all 100ms ease;
  height: 100%;
  padding-bottom: 20px;
  @include on-event {
    cursor: pointer;
  }
  &.selected .imageTile {
    .imageTile__info {
      @include breakpoint(tablet) {
        background-color: $xcolor--accent;
      }
    }
  }
}
.imageTile {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  transition: all 100ms ease;
}

.imageTile--file {
  height: 100%;
  .imageTile__preview {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.imageTile__preview {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #eee;
  height: 250px;
  .image {
    opacity: 0;
  }
}

.imageTile__preview--svg {
  background-size: contain;
}

.imageTile__info {
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  color: #000;
  padding: 15px 0 0 0;
  line-height: 120%;
  align-items: center;
  transition: all 100ms ease;
  font-size: 14px;
  @include breakpoint(tablet) {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 15px;
    background-color: rgba($xcolor--bg, 0.75);
    width: 100%;
  }
  .name {
    font-weight: 500;
    line-height: 30px;
    font-size: 16px;
  }
  .size,
  .filesize {
    font-size: 14px;
    color: $xcolor--faded-grey;
  }
}

.imageTile__info__label {
  margin-bottom: 6px;
  @include breakpoint(tablet) {
    margin-bottom: 0;
  }
}

.imageTile__info__actions {
  overflow: visible;
  @include breakpoint(tablet) {
    text-align: right;
    .action:not(:first-child) {
      margin-left: 6px;
      margin-top: 0;
      button {
        color: #fff;
      }
    }
  }
  .action {
    &:not(:first-child) {
      margin-left: 6px;
    }
    button {
      background: transparent;
      outline: none;
      border: none;
    }
  }
  .action--usage {
    font-weight: 500;
  }
}


.backoffice {
  .imageTile-wrapper {
    @include on-event {
      cursor: default;
    }
  }
}
