[v-cloak]{
  display: none;
}

.body-xcms {
  position: relative;
  z-index: 9999;
}


nav.menu{
  background: #000;
  width: 100%;
  z-index: 200;
  position: relative;
  float:left;
  padding: 10px;
  padding-left: 0;
}

ul.menu{
  margin: 0;
  padding: 0;
}

ul.menu > li{
  float: left;
  margin-right: 15px;
}

ul.menu a{
  color: #fff;
}
/*
.panel-group-header{
    border: 1px solid #eee;
    border-bottom: 0;
    padding: 8px;
    background: #eee;
    height: 45px;
    width: 100%;
    overflow: hidden;
}

.panel-group-header h3{
    margin: 0;
    float: left;
    width: calc(100% - 50px);
    font-size: 22px;
    font-weight: normal;
}

.panel-group.collapsed{
    height: 0;
    overflow: hidden;
    padding: 0;
}

.panel-group.collapsed.preview{
    height: 60px;
    font-size: 18px;
    padding: 5px;
    color: #aaa;

}

.panel-group button.btn.btn-orange{
    margin-bottom: 0; margin-top: 15px;

}

.panel-group button.btn.btn-orange.btn-save{
    display: none;
}

.panel-group:hover{
    border: 1px solid #ddd;
}

.panel-group:hover button.btn.btn-orange.btn-save{
    display: block;
}

.panel-group label > .text{
    display: inline-block; margin-top: 10px;
}

.panel-group label > .languages{
    margin-bottom: 5px;float: right;
}

.panel-group .data-record{
    padding: 5px 5px;
    height: 38px;
}

.panel-group .data-record .data-record-label, .admin .panel-group .data-record .data-record-link{
    color: #000; font-size: 20px;
}*/
