// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

$xcolor--form-error: #e82222;
$xcolor--form-warning: #e8632a;

.form__fieldset {
  margin-top: 16px;
  padding: 20px;

  legend {
    background-color: $xcolor--bg;
    color: $xcolor--text-light;
    padding: 5px 10px;
  }

  .form__field {
    padding-bottom: 10px;
  }
}

.form__field {
  position: relative;
  margin-top: 16px;
  padding-bottom: 32px;

  input::-webkit-input-placeholder { color:transparent;transition: 0.3s; }
  input:-moz-placeholder { color:transparent;transition: 0.3s; } /* FF 4-18 */
  input::-moz-placeholder { color:transparent;transition: 0.3s; } /* FF 19+ */
  input:-ms-input-placeholder { color:transparent;transition: 0.3s; } /* IE 10+ */

  input:focus::-webkit-input-placeholder { color:#b2b2b2; }
  input:focus:-moz-placeholder { color:#b2b2b2; } /* FF 4-18 */
  input:focus::-moz-placeholder { color:#b2b2b2; } /* FF 19+ */
  input:focus:-ms-input-placeholder { color:#b2b2b2; } /* IE 10+ */

  textarea {
    resize: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border-radius: 0px;
    height: 40px;
  }

  &.form__field--no-title {
    margin-top: 0;
  }
}

// General styles
.form__field-label {
  position: absolute;
  top: 16px;
  left: 0;
  font-family: $xfontstack--bold;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  cursor: text;
  text-transform: uppercase;
  color: $xcolor--faded-grey;
  letter-spacing: 2px;
  width: 200px;
  text-align: right;
  transform: translateX(-200px);
  padding: 0 40px 0 10px;

  &.form__field-checkbox-label {
    top: 12px;
  }

  .global {
    margin-left: 4px;
  }
}

.form__label-subscript {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 200;
  color: $xcolor--faded-grey;
  letter-spacing: 2px;
}

.form__field-input, .form__field-input-prefix {
  width: 100%;
  padding: 12px 0;
  outline: none;
  border: 0;
  font-size: 18px;
  line-height: 18px;
  border-bottom: solid 1px $xcolor--light-grey;
  transition: 0.3s;

  &.form__field-input--readonly {
    opacity: 0.5;
  }

  &:focus {
    border-bottom: solid 1px $xcolor--bg;
    /*box-shadow: 0 1px 0 0 $xcolor--bg;*/
  }

  //NIEUWE STYLING
  /*&:focus ~ .form__field-label,
  &.form__field-input--active ~ .form__field-label,
  ~ .form__field-label--fixed {
    opacity: 0.6;
    transform: translateY(-28px);
  }*/

  &:focus ~ .form__field-label {
    //color: $xcolor--accent;
  }
}

.form__field {
  &.form__field--compact {
    margin-top: 6px;
    padding-bottom: 12px;

    .form__field-input {
      padding: 5px 0;
      font-size: 14px;
      line-height: 14px;

      //NIEUWE STYLING
      /*&:focus ~ .form__field-label,
      &.form__field-input--active ~ .form__field-label,
      ~ .form__field-label--fixed {
        transform: translateY(-23px);
      }*/
    }

    .form__field-label {
      font-size: 12px;
      top: 8px;
    }
  }
}

.form__field {
  &.form__field--error {

    .form__field-input {
      border-bottom: solid 1px $xcolor--form-error;
      box-shadow: 0 1px 0 0 $xcolor--form-error;
    }

    .form__field-label {
      color: $xcolor--form-error;
    }
  }
}

.form__field-input-prefix {
  flex: 1;
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.form__field-admin-actions, .form__field-subscript, .form__field-slug {
  opacity: 0.5;
  font-size: 12px;
  font-weight: 400;
  margin-top: 3px;
}

.form__field-admin-actions {
  height: 0;

  a {
    cursor: pointer;
  }
}

.form__field-subscript, .form__field-slug {
  position: absolute;
  right: 0;
}

.field-subscript__charcounter {
  &.field-subscript__charcounter--warning {
    color: $xcolor--form-warning;
  }
}

.form__field-autocomplete {

  &.form__field-input:focus {
    border-left: solid 2px black;
    border-top: solid 2px black;
    border-right: solid 2px black;
    padding-left: 10px;
  }
}

ul.form__field-autocomplete-list {
  border-left: solid 1px black;
  border-bottom: solid 1px black;
  border-right: solid 1px black;

  li {
    font-size: 16px;
    padding: 10px;
    border-bottom: solid 1px $xcolor--white;
    cursor: pointer;

    &:hover, &.active {
      background-color: $xcolor--bg;
      color: $xcolor--text-light;
    }
  }

  li:nth-last-child(1) {
    border-bottom: none;
  }
}

.form__field--checkbox-list {
  .form__field-input {
    border: none;
    padding: 5px 0;
  }
  .form__field-checkbox-label {
    top: 9px;
    text-align: left;
    left: 275px;
    width: 250px;
    padding: 0;
  }
}


// Medium editor
.form__field-medium-editor {
  font-size: 18px;
  line-height: 150%;
  padding: 10px 0;
  h3 {
    font-size: 16px;
    padding: 10px 0 5px 1px;
  }
  ul {
    padding: 5px 24px;
  }
  p {
    padding: 0 0 5px 0;
  }
  a, a:hover, a:active, a:visited {
    color: $xcolor--accent;
    text-decoration: underline;
  }
}

.form__field-medium-editor, textarea {
  transition: 0.3s;
}


// HTML source editor
.form__field {
  .form__field-html-source-editor {
    resize: vertical;
    font-size: 12px;
    font-family: monospace, 'Courier';
    min-height: 250px;
  }
}


.form-field-list {
  list-style-type: none;
  margin-bottom: 15px;

  li {
    border: solid 1px $xcolor--white;
    padding: 25px 15px 15px;
    margin-bottom: 2px;
  }

}


.form__list__action-button {
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px $xcolor--light-grey;
  padding: 4px 0 15px 0;
}

.form__validation-list {
  list-style-type: none;
  margin-bottom: 15px;
  transition: 0.3s;

  li {
    margin: 2px 0;
    padding: 5px;
    background-color: $xcolor--form-error;
    color: #FFF;
  }
}

/* always present */
.validation-list-transition {
  transition: all 1.3s ease;
  opacity: 1;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.validation-list-enter, .validation-list-leave {
  opacity: 0;
}


.form__footer {
  padding: 40px 0 0 0;

  .left, .center, .right {
    display: flex;
  }

  .left {
    justify-content: flex-start;
  }
  .center {
    justify-content: center;
  }
  .right {
    justify-content: flex-end;
  }
}
