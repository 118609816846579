.featherlight {
  .featherlight-close-icon {
    border: none;
    color: #fff;
    background: transparent;
    text-align: right;
    font-size: 24px;
    top: -35px;
    right: -35px;
    outline: none;

    background-image: $xsprite;
    background-image: $xsprite--svg;
    width: 22px;
    height: 22px;
    background-position-x: -310px;
    background-position-y: -5px;

    transition: 0.1s all ease-in-out;

    &:hover {
      transform: scale(0.85, 0.85);
    }
  }

  .featherlight-content {
    padding: 0;
    border-bottom: none;
    overflow: visible;
    background: transparent;
  }

  iframe.featherlight-inner {
    max-width: none !important;
  }
}