.imageField__fileList {
  list-style: none;
  padding: 0;
}

.fileList__item {
  position: relative;
  background: $xcolor--white;
}

// Override ImageTile for this specific field.
.fileList__item .imageTile{
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 120px;
  border-bottom: solid 1px $xcolor--light-grey;

  .imageTile__preview {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: solid 1px $xcolor--light-grey;
  }

  .imageTile__info {
    width: auto;
    position: static;
    height: auto;
    flex-grow: 1;
    background-color: transparent;
    color: initial;
    padding: 20px;
    letter-spacing: 1px;

    .name {
      line-height: 26px;
      font-size: 16px;
    }

    .filesize {
      font-weight: 200;
    }
  }

  .imageTile__info__actions {
    button {
      color: initial;
    }
  }

}
