// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains styles concerning the navigation(s) of the Core.
// -----------------------------------------------------------------------------

.xnav-mob{
  display: none;
}
.xnav{
  ul{
    list-style-type: none;
    li{
      display: inline;
      a{
        color: $xcolor--text;
      }
    }
    li:first-child{
      a{
        margin-left: 0 !important;
      }
    }
    li:last-child{
      a{
        margin-right: 0 !important;
      }
    }
  }
}
.xmobile-menu{
  @include breakpoint(desktop){
    display: none;
  }
}

.xaction-btn{
  font-family: $xfontstack--bold;
  font-size: $xbtn__fontsize;
  color: $xcolor--text;
  @include breakpoint(tablet){
    font-size: $xbtn__fontsize--tablet;
  }
  @include breakpoint(desktop){
    font-size: $xbtn__fontsize--desktop;
  }
  @include breakpoint(wide){
    font-size: $xbtn__fontsize--wide;
  }
}

.xhamburger{
  width: 60px;
  height: 60px;
  right: 0;
  top: 0;
  cursor: pointer;
  &.xhamburger-default{
    .xhamburger-icon{
      width: 30px;
      height: 2px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -10px;
      background-color: $xcolor--icon;
      &:after, &:before{
        content: '';
        display: block;
        background-color: $xcolor--icon;
        width: 100%;
        height: 2px;
        position: absolute;
      }
      &:before{
        top: 8px;
      }
      &:after{
        top: 16px;
      }
    }
    .xclose-icon{
      top: 50%;
      left: 50%;
      margin-top: -15px;
      opacity: 0;
    }
  }
  @include breakpoint(desktop){
    display: none;
  }
}

.xhamburger--top-to-bottom{
  .xclose-icon{
    transform: translate(0px, -10px) rotate(45deg);
  }
}

.xclose-icon{
  width: 30px;
  height: 30px;
  transform:rotate(45deg);
  -ms-transform:rotate(45deg);
  -webkit-transform:rotate(45deg);
}

.xclose-icon:before, .xclose-icon:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: $xcolor--icon;
}

.xclose-icon:before {
  left: 50%;
  top: 0;
  width: 2px;
  margin-left: -1px;
  height: 100%;
}

.xclose-icon:after {
  top: 50%;
  left: 0;
  height: 2px;
  margin-top: -1px;
  width: 100%;
}
