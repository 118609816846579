.top-menu {
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index: 9999;
  width: 100%;
  height: 36px;
  background: $xcolor--bg;
  border-bottom: 1px solid #fff;
  color: #fff;

  .button {
    border-radius: 0;
    width: 100%;
    height: 100%;
    padding: 10px 5px;
    display: block;
    &:hover, &:focus {
      color: #000;
      background: #fff;
    }
  }
}

.top-menu__inner {
  opacity: 0;
  max-width: $xsite__max-width;
  margin: 0 auto;
  padding-right: $xsite__padding;
  animation: fadeTopMenuInner 500ms;
  animation-delay: 800ms;
  animation-fill-mode: forwards;
  @include breakpoint(tablet) {
    padding-right: $xsite__padding--tablet;
  }
  @include breakpoint(desktop) {
    padding-left: $xsite__padding--desktop;
    padding-right: $xsite__padding--desktop;
  }
  @include breakpoint(wide) {
    padding-left: $xsite__padding--wide;
    padding-right: $xsite__padding--wide;
  }
}
@keyframes fadeTopMenuInner {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.top-menu__user {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}