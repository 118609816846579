// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('../../fonts/boness/HelveticaNeueMedium.woff2') format('woff2'),
        url('../../fonts/boness/HelveticaNeueMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueBold';
  src: url('../../fonts/boness/HelveticaNeueBold.woff2') format('woff2'),
        url('../../fonts/boness/HelveticaNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}