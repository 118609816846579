.selectric {
  border: 1px solid $xcolor--light-grey;
  background: $xcolor--white;
  transition: all 0.1s;
}

.selectric-focus .selectric {
  border-color: $xcolor--bg;
}

.selectric .label {
  font-size: 14px;
  line-height: 50px;
  color: $xcolor--bg;
  height: 50px;
  margin: 0 38px 0 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.selectric .button {
  width: 50px;
  height: 50px;
  background-color: transparent;
  color: $xcolor--dark-grey;

  &:after {
    display: none;
  }

  .icon {
    top: 21px;
    left: 20px;
  }
}

.selectric-items {
  background: $xcolor--white;
  border: 1px solid $xcolor--light-grey;
  transition: all 0.1s;
}

.selectric-items li {
  min-height: 40px;
  padding: 10px 20px;
  color: $xcolor--bg;
}

.selectric-items li.selected {
  background: $xcolor--accent;
  color: $xcolor--white;
  font-weight: bold;
}

.selectric-items li.highlighted {
  background: $xcolor--accent;
  color: $xcolor--white;
}

.selectric-items li:hover {
  background: $xcolor--accent;
  color: $xcolor--white;
}

.selectric-items ul, .selectric-items li {
  font-size: 14px;
  line-height: 20px;
  min-height: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
}