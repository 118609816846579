.nav-tabs {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: 60px;

  li {
    margin-right: 2px;
    a {
      width: 120px;
      display: inline-block;
      text-align: center;
      padding: 20px;
      background-color: $xcolor--bg;
      color: $xcolor--text-light;
      cursor: pointer;
      border-left: 1px solid $xcolor--white;
      border-right: 1px solid $xcolor--white;
    }
  }

  li.active a {
    box-shadow: 0 -2px 0 0 $xcolor--accent;
    background-color: transparent;
    color: $xcolor--text;
  }
}
