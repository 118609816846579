.data-table-preview {
  position: relative;
  margin: 16px 0 52px 0;
}

.data-table-preview {
  .form__field-label {
    top: 22px;
  }
}

.data-table-preview__action-button {
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px $xcolor--light-grey;
  padding: 10px 0 25px 15px;
}

.data-table-preview__row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 68px;
  font-size: 20px;
  line-height: 150%;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  z-index: 1;
  border-bottom: solid 1px $xcolor--light-grey;
  opacity: 1;
  transition: 0.2s all;

  &:nth-child(n+5) {
    height: 0;
    opacity: 0;
    border-bottom: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    z-index: -1;
    opacity: 0;
    transition: all 0.1s;
  }

  &:hover {
    color: $xcolor--white;
    &:after {
      opacity: 1;
    }
    .icon-col {
      .drag {
        transform: translateY(0);
      }
      .arrow {
        opacity: 1;
      }
    }
  }
}

.data-table-preview--expand {
  @for $i from 5 through 20 {
    .data-table-preview__row:nth-child(#{$i}) {
      height: 68px;
      opacity: 1;
      border-bottom: solid 1px $xcolor--light-grey;
      transition: height 0.05s, opacity 0.05s;
      transition-delay: ($i * 0.05s) - 0.25s;
    }
  }
  .data-table-preview__row:nth-child(n+20) {
    height: 68px;
    opacity: 1;
    border-bottom: solid 1px $xcolor--light-grey;
    transition: height 0.05s, opacity 0.05s;
    transition-delay: 0.75s;
  }
}

.data-table-preview__col {
  display: flex;

  &.text-col {
    flex-grow: 1;
    height: 100%;
    align-items: center;
    padding: 0 30px 0 15px;
    min-width: 0; //https://css-tricks.com/flexbox-truncated-text/
  }

  &.icon-col {
    flex: 0 0 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 15px 0 0;

    .drag {
      width: 12px;
      height: 8px;
      border-top: solid 2px $xcolor--light-grey;
      border-bottom: solid 2px $xcolor--light-grey;
      transform: translateX(34px);
      transition: all 0.2s;
    }

    .arrow {
      margin-left: 15px;
      opacity: 0;
      transition: all 0.2s;
    }
  }
}

.data-table-preview__footer {
  margin-top: 25px;
  padding: 0 15px;

  .center {
    justify-content: center;
  }
}