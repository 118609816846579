// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.sc-button {
  cursor: pointer;
  text-decoration: none;
  text-transform: lowercase;
  border: none;
  user-select: none;
  opacity: 1;
  transition: all 0.1s;
  background: transparent;

  &:focus {
    outline:0;
  }

  &:hover {
    text-decoration: none;
  }
}

.sc-button--hidden {
  opacity: 0;
}

.sc-button--icon {
  &.stretch {
    width: 100%;
    height: 100%;
  }

  .xabf {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 0.1s ease-in-out;
  }

  .move-left:nth-child(1) {
    transform: translateX(4px);
  }
  .move-left:nth-child(2) {
    transform: translateX(20px);
  }

  &:hover {
    .move-left:nth-child(1) {
      transform: translateX(-4px);
    }
    .move-left:nth-child(2) {
      transform: translateX(0px);
    }
  }
}

.sc-button--close {
  &:hover {
    .xabf {
      transform: scale(0.85, 0.85);
    }
  }
}


.sc-button--primary {
  position: relative;
  color: $xcolor--text-light;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  height: 40px;
  line-height: 40px;
  padding: 0 46px 0 20px;
  text-transform: uppercase;
  border-radius: 20px;
  z-index: 1;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    transition: all 0.1s;
  }

  &:before {
    background: $xcolor--bg;
    opacity: 1;
    z-index: -1;
  }

  &:after {
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    opacity: 0;
    z-index: -1;
  }

  .icon {
    top: 15px;
    right: 22px;
    transition: all 0.2s;
  }

  &:hover {
    color: $xcolor--white;

    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }

    .icon {
      transform: translateY(2px);
    }
  }
}

.sc-button--secondary {
  position: relative;
  color: $xcolor--faded-grey;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  border-radius: 25px;
  transition: all 0.1s;
  z-index: 1;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    transition: all 0.1s;
  }

  &:before {
    border: solid 2px $xcolor--light-grey;
    opacity: 1;
    z-index: -1;
  }

  &:after {
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    opacity: 0;
    z-index: -1;
  }

  &:hover {
    color: $xcolor--white;

    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }

  &.sc-button--confirm-delete {
    color: $xcolor--white;
    &:before {
      border: none;
      background: linear-gradient(150deg, #D73636 50%, #980303);
    }
    &:after {
      background: linear-gradient(150deg, #f73f3f 50%, #D73636);
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &.sc-button--cancel {
    &:after {
      display: none;
    }
    &:hover {
      color: $xcolor--medium-grey;
      &:before {
        opacity: 1;
        border: solid 2px $xcolor--faded-grey;
      }
    }
  }
}

.sc-button--submit {
  position: relative;
  color: $xcolor--text-light;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  height: 50px;
  line-height: 50px;
  padding: 0 25px;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 0;
  transition: all 0.1s;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 25px;
    transition: all 0.1s;
  }

  &:before {
    z-index: -1;
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    opacity: 1;
  }

  &:after {
    z-index: -2;
    background: $xcolor--accent-gradient-light;
  }

  &:hover {
    color: $xcolor--white;
    &:before {
      opacity: 0;
    }
  }

  &:active {
    &:after {
      background: $xcolor--accent;
    }
  }
}



.sc-button--simple-text {
  background: none;
  color: $xcolor--faded-grey;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  height: 30px;
  line-height: 30px;
  text-transform: uppercase;
  transition: all 0.1s;

  &:hover {
    color: $xcolor--accent;
  }
}

.sc-button--language {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 13px;
  color: $xcolor--white;
  border-radius: 0;
  height: 40px;
  transition: all 0.1s;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    left: 0;
    margin-left: 50%;
    bottom: 0;
    height: 3px;
    background: $xcolor--accent-gradient-light;
    transition: all 0.1s;
  }

  &.active {
    color: $xcolor--accent-gradient-light;
    &:after {
      width: 100%;
      left: -50%;
    }
  }
}

.sc-language-switcher:hover {
  .sc-button--language {
    &.active:after {
      width: 0;
      left: 0;
    }
    &:hover:after {
      width: 100%;
      left: -50%;
    }
  }
}


.action-button {
  border: none;
  color: #B2B2B2;
  padding: 15px 32px;
  text-align: center;
  font-size: 12px;
  height: 48px;

  .fa {
    position: relative;
    left: -8px;
    top: 1px;
  }

  &.primary-button {
    &.sc-button--disabled {
      opacity: 0.5;
    }
  }
}


.add-new-button {
  font-family: $xfontstack--bold;
  font-size: 12px;
  font-weight: $fontweight--bold;
  color: $xcolor--accent;
  height: 32px;
  padding: 5px;

  .fa {
    padding: 5px;
  }
}

.sc-row-with-circle-button {
  .header-arrow-down, .header-arrow-up {
    transition: transform 0.1s;
  }
  &:hover {
    .header-arrow-down {
      transform: translateY(5px);
    }
    .header-arrow-up {
      transform: translateY(-5px);
    }
  }
}






// -----------------------------------------------------------------------------
// Circular buttons
// -----------------------------------------------------------------------------

.sc-button--circle-and-label {
  align-items: center;
}

//base class for circle related styling, may be overwritten for specific buttons
.sc-button__circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transition: all 0.1s;
  }

  &:before {
    z-index: 1;
  }
  &:after {
    z-index: -1;
  }

  > div {
    z-index: 1;
    transition: all 0.1s;
  }

  > .xab { position: relative; }
}

.sc-button--circle, .sc-button--circle-and-label {
  .sc-button__clickzone {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .sc-button__circle {
    width: 40px;
    height: 40px;
    &:before, &:after {
      width: 40px;
      height: 40px;
    }
  }
  &.large-circle {
    .sc-button__circle {
      width: 50px;
      height: 50px;
      &:before, &:after {
        width: 50px;
        height: 50px;
      }
    }
  }
  &.small-circle {
    .sc-button__circle {
      width: 30px;
      height: 30px;
      &:before, &:after {
        width: 30px;
        height: 30px;
      }
    }
  }
}

//base class for label related styling
.sc-button__label {
  margin-left: 15px;
  color: $xcolor--faded-grey;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}


.sc-button--delete-multiple {
  .sc-button__circle {
    &:before {
      border: solid 2px $xcolor--medium-grey;
      opacity: 1;
    }
    &:after {
      background: linear-gradient(150deg, #D73636 50%, #980303);
      opacity: 0;
    }
  }
  .sc-button__label {
    color: $xcolor--faded-grey;
    margin-left: 35px;
  }
  &:hover {
    .sc-button__circle {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
    .sc-button__label {
      color: #B81D1D;
    }
  }
}

.sc-button--list-item-delete {
  .sc-button__icon {
    width: 18px;
    height: 18px;
  }
  .sc-button__circle {
    &:before {
      display: none;
    }
    &:after {
      background: linear-gradient(150deg, #D73636 50%, #980303);
    }
    .icon-grey {
      opacity: 1;
    }
    &:after, .icon-white {
      opacity: 0;
    }
  }
  &:hover {
    .sc-button__circle {
      &:after, .icon-white {
        opacity: 1;
      }
      .icon-grey {
        opacity: 0;
      }
    }
    .sc-button__label {
      color: #B81D1D;
    }
  }
}

.sc-button--delete-detail {
  .sc-button__icon {
    width: 18px;
    height: 18px;
  }
  .sc-button__circle {
    &:before {
      border: solid 2px $xcolor--light-grey;
    }
    &:after {
      background: linear-gradient(150deg, #D73636 50%, #980303);
    }
    &:before, .icon-grey {
      opacity: 1;
    }
    &:after, .icon-white {
      opacity: 0;
    }
  }
  .sc-button__label {
    color: $xcolor--faded-grey;
    margin-right: 15px;
  }
  &:hover {
    .sc-button__circle {
      &:before, .icon-grey {
        opacity: 0;
      }
      &:after, .icon-white {
        opacity: 1;
      }
    }
    .sc-button__label {
      color: #B81D1D;
    }
  }
}


.sc-button--overview-addnew {
  .sc-button__circle {
    &:before {
      border: solid 2px $xcolor--medium-grey;
      opacity: 1;
    }
    &:after {
      background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
      opacity: 0;
    }
  }
  .sc-button__label {
    color: $xcolor--medium-faded-grey;
  }
  &:hover {
    .sc-button__circle {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
    .sc-button__label {
      color: $xcolor--accent-gradient-light;
    }
  }
}


.sc-button--list-addnew {
  .sc-button__icon {
    width: 16px;
    height: 18px;
  }
  .sc-button__circle {
    &:before {
      border: solid 2px $xcolor--light-grey;
    }
    &:after {
      background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    }
    &:before, .icon-black {
      opacity: 1;
    }
    &:after, .icon-white {
      opacity: 0;
    }
  }
  .sc-button__label {
    color: $xcolor--faded-grey;
  }
  &:hover {
    .sc-button__circle {
      &:before, .icon-black {
        opacity: 0;
      }
      &:after, .icon-white {
        opacity: 1;
      }
    }
    .sc-button__label {
      color: $xcolor--accent-gradient-light;
    }
  }
}


.sc-button--checkbox {
  .sc-button__circle {
    flex: 0 0 40px;

    &:before {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: $xcolor--white;
      z-index: 1;
      margin: 3px;
      width: 36px;
      height: 36px;
    }
    &:after {
      background: $xcolor--light-grey;;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 1;
      transition: all 0.1s;
    }
    .icon {
      opacity: 0;
      transition: all 0.1s;
    }
  }
  &:hover, &.checked, &.checked:hover {
    .sc-button__circle {
      &:after {
        background: linear-gradient(150deg, #93FF69 50%, #3DD015);
      }
    }
  }
  &.checked {
    .sc-button__circle {
      .icon {
        opacity: 1;
      }
    }
  }
}


.sc-button--list-checkbox {
  &.small-circle {
    .sc-button__circle:before {
      width: 26px;
      height: 26px;
    }
  }

  .sc-button__circle {
    &:before {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: $xcolor--bg;
      z-index: 1;
      margin: 3px;
    }
    &:after {
      background: $xcolor--medium-grey;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 1;
      transition: all 0.1s;
    }
    .icon {
      opacity: 0;
      transition: all 0.1s;
    }
  }
  &:hover, &.checked, &.checked:hover {
    .sc-button__circle {
      &:after {
        background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
      }
    }
  }
  &.checked {
    .sc-button__circle {
      .icon {
        opacity: 1;
      }
    }
  }
}


.sc-button--pager {
  .sc-button__circle {
    &:before {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: $xcolor--bg;
      z-index: 1;
      margin: 3px;
      width: 36px;
      height: 36px;
    }
    &:after {
      background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: all 0.1s;
    }
  }
  &:hover {
    .sc-button__circle {
      &:after {
        opacity: 1;
      }
    }
  }
  &.active, &.active:hover {
    .sc-button__circle {
      &:after {
        background: $xcolor--white;
        opacity: 1;
      }
    }
  }
}

.sc-button--cms-launcher {
  &.large-circle {
    .sc-button__circle:before {
      width: 46px;
      height: 46px;
    }
  }

  .sc-button__circle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;

    &:before {
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: $xcolor--white;
      z-index: 1;
      margin: 3px;
      width: 36px;
      height: 36px;
    }

    &:after {
      background: $xcolor--light-grey;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 1;
      transition: all 0.1s;
    }
  }
  &:hover {
    .sc-button__circle {
      &:after {
        background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
      }
    }
  }
}