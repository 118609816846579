$xcolor--medium-editor-bg: #000000;

.medium-toolbar-arrow-under:after {
  border-color: $xcolor--medium-editor-bg transparent transparent transparent;
  top: 50px;
}

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent $xcolor--medium-editor-bg transparent;
  top: -8px;
}

.medium-editor-toolbar {
  background-color: $xcolor--medium-editor-bg;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.medium-editor-toolbar li button {
  position: relative;
  background-color: $xcolor--medium-editor-bg;
  border: 0;
  border-left: 1px solid $xcolor--medium-grey;
  color: $xcolor--white;
  height: 50px;
  min-width: 50px;
  transition: all 0.1s;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-image: url($xbase-url + 'images/boness/skinn_cms_editor_sprite.png');
    background-image: url($xbase-url + 'images/boness/skinn_cms_editor_sprite.svg');
  }

  b {
    display: none;
  }
}

.medium-editor-toolbar li button:hover {
  background-color: $xcolor--medium-grey;
}

.medium-editor-toolbar li .medium-editor-button-active {
  background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
}

.medium-editor-toolbar li button.medium-editor-action-bold:before {
  width: 10px;
  height: 12px;
  background-position-x: -20px;
  background-position-y: -20px;
  margin-top: 19px;
  margin-left: 20px;
}

.medium-editor-toolbar li button.medium-editor-action-anchor:before {
  width: 19px;
  height: 8px;
  background-position-x: -60px;
  background-position-y: -22px;
  margin-top: 21px;
  margin-left: 15px;
}

.medium-editor-toolbar li button.medium-editor-action-unorderedlist:before {
  width: 15px;
  height: 8px;
  background-position-x: -107px;
  background-position-y: -22px;
  margin-top: 21px;
  margin-left: 16px;
}

.medium-editor-toolbar-form {
  background: $xcolor--medium-editor-bg;
  border-radius: 5px;
  color: #999;
  width: 390px;

  &:before {
    content: '';
    position: absolute;
    background-image: url($xbase-url + 'images/boness/skinn_cms_editor_sprite.png');
    background-image: url($xbase-url + 'images/boness/skinn_cms_editor_sprite.svg');
    width: 19px;
    height: 8px;
    background-position-x: -60px;
    background-position-y: -22px;
    top: 18px;
    left: 16px;
  }
}

.medium-editor-toolbar-form .medium-editor-toolbar-input,
.medium-editor-toolbar-form .medium-editor-toolbar-input:focus {
  background: $xcolor--bg;
  color: $xcolor--white;
  font-size: 16px;
  width: 315px;
  height: 44px;
  line-height: 44px;
  padding: 0 15px 0 50px;
  border-bottom: solid 1px $xcolor--medium-grey;
}

.medium-editor-toolbar-form .medium-editor-toolbar-form-row {
  background: $xcolor--bg;
  color: $xcolor--medium-faded-grey;
  font-size: 0; //fix extra margin for inline-block elements
  width: 315px;
  height: 44px;
  padding: 0 17px;
  margin: 0;

  input {
    display: inline-block;
    font-size: 16px;
  }

  label {
    display: inline-block;
    line-height: 44px;
    width: auto;
    padding: 0 0 0 20px;
  }
}

.medium-editor-toolbar-form a.medium-editor-toolbar-save {
  position: absolute;
  display: inline;
  top: 20px;
  right: 12px;
  margin: 0;
  font-size: 0;
  width: 50px;
  height: 50px;

  &:before {
    content: 'ok';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: solid 2px $xcolor--medium-grey;
    background: $xcolor--bg;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    line-height: 20px;
    padding-top: 13px;
  }

  &:after {
    content: 'ok';
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    line-height: 20px;
    padding-top: 15px;
    opacity: 0;
    transition: all 0.1s;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.medium-editor-toolbar-form a.medium-editor-toolbar-close {
  display: none;
}

.medium-editor-toolbar-form a {
  color: #fff;
}

.medium-editor-toolbar-anchor-preview {
  background: $xcolor--medium-editor-bg;
  color: #fff;
}

.medium-editor-placeholder:after {
  color: #b3b3b1;
}

.medium-editor-element {
  outline: none;
}

.medium-editor-anchor-preview, .medium-editor-toolbar {
  z-index: 20000 !important;
}

.medium-editor-anchor-preview {
  max-width: 350px;
}