$panel__header-height: 135px;
$panel__header-height--shrink: 77px;
$panel__margin: 10px;
$form-width: 700px;
$form-side-margin: 5px;

.panel-container {
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  animation-name: revealPanelContainer;
  animation-iteration-count: 1;
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

.panel {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: $xsite__max-width;
  min-height: 100vh;

  &.panel--dark {
    > .panel__content {
      background-color: $xcolor--bg;
      > .panel__content__inner {
        padding-top: 0;
      }
    }
  }

  animation-name: revealPanel;
  animation-iteration-count: 1;
  animation-duration: 0.1s;
  animation-delay: 0.3s;
  animation-fill-mode: both;

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 1px;
    top: 0;
    background-color: $xcolor--medium-grey;
    z-index: 101;
  }
  &:before {
    left: -1px;
  }
  &:after {
    right: -1px;
  }
}


.panel__header {
  position: fixed;
  backface-visibility: hidden;
  transform: translateZ(0);
  width: 100%;
  height: $panel__header-height;
  max-width: $xsite__max-width;
  color: #FFF;
  background-color: $xcolor--bg;
  border-bottom: solid 1px $xcolor--medium-grey;
  z-index: 100;
  transition: all 0.2s;

  &.panel__header--shrink {
    height: $panel__header-height--shrink;

    .header__main {
      margin: 0;

      .header__col--middle {
        padding-top: 0;
      }
    }

    .header__subtitle {
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
      height: 0;
    }

    .header__title {
      font-size: 20px;
      height: 24px;
      margin: 10px 0 2px 0;
    }
  }

  .panel__header__inner {
    background-color: $xcolor--bg;
  }

  .header__subtitle {
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: 0.2s all;
    // Copied from xtitle--small for temporary fix
    font-family: $xtitle__font--small;
    font-size: $xtitle__fontsize--small;
    line-height: $xtitle__lineheight--small;
    font-weight: 400;
    @include breakpoint(tablet) {
      font-size: $xtitle__fontsize--small--tablet;
    }
    @include breakpoint(desktop) {
      font-size: $xtitle__fontsize--small--desktop;
    }
    @include breakpoint(wide) {
      font-size: $xtitle__fontsize--small--wide;
    }
  }

  .header__main {
    display: flex;
    transition: 0.2s all;

    .header__col--left, .header__col--right {
      flex: 0 0 150px;
      display: flex;
      align-items: center;
    }

    .header__col--left {
      justify-content: flex-end;
    }

    .header__col--right {
      justify-content: flex-start;
    }

    .header__col--middle {
      flex-grow: 1;
      min-width: 0; //flexbox-truncated-text
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      transition: 0.2s all;
    }
  }

  .header__title {
    width: 100%;
    height: 50px;
    max-width: $form-width;
    margin: 6px auto;
    padding: 0 20px;
    opacity: 1;
    letter-spacing: 1px;
    transition: all 0.2s;
    // Copied from xtitle--medium for temporary fix
    font-family: $xfontstack--helveticaneue;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    font-size: $xtitle__fontsize--large;
    line-height: 120%;
    @include breakpoint(tablet) {
      font-size: $xtitle__fontsize--large--tablet;
    }
    @include breakpoint(desktop) {
      font-size: $xtitle__fontsize--large--desktop;
    }
    @include breakpoint(wide) {
      font-size: $xtitle__fontsize--large--wide;
    }
  }


}

.panel__content {
  min-height: calc(100vh);
  background-color: #FFF;
  padding-top: #{$panel__header-height};

  .panel__content__inner {
    padding: 100px 0px;
  }

  .panel__content-header {
    height: 70px;

    .buttons {
      height: 60px;
    }
  }

  .panel__content-form {
    margin: 0 auto;
    width: 100%;
    max-width: $form-width;
    padding: 0 $form-side-margin $panel__header-height $form-side-margin;
  }

  .panel__content-centered {
    margin: 0 auto;
    width: 100%;
    max-width: $form-width;
    padding: 0 $form-side-margin $panel__header-height $form-side-margin;
  }
}

.panel__footer {
  position: fixed;
  backface-visibility: hidden;
  transform: translateZ(0);
  bottom: 0;
  left: 0;
  width: calc(100% - (#{$panel__margin} * 2));
  margin: 0 $panel__margin;

  .panel__footer__inner {
    margin: 0 auto;
    width: 100%;
    max-width: $xsite__max-width;
  }

  .panel__footer__buttons {
    margin: 0 auto;
    width: 100%;
    max-width: $form-width;
    height: 48px;
    padding: 0 $form-side-margin 0 $form-side-margin;
  }
}

.panel__fieldgroup {

  .panel__fieldgroup-content {
    padding: 10px 0;
  }

}

.main-menu-overlay {

  .panel__header {
    height: $panel__header-height--shrink;
  }

  .panel__content {
    padding-top: $panel__header-height--shrink;
    display:flex;
    justify-content:center;
    align-items:center;
  }

}


// Backoffice specific changes
.backoffice {

  .panel {
    padding: 0;
    max-width: none;
  }

  .panel-container {
    overflow-y: auto;
    transition: all 150ms ease;
    @include breakpoint(tablet) {
      margin-left: $sideBar-width;
      width: calc(100% - #{$sideBar-width});
    }
  }

  .panel__header,
  .panel__content,
  .panel__footer {
    margin: 0;
    width: 100%;
    max-width: none;
    padding-left: $xsite-_padding;
    padding-right: $xsite-_padding;
    padding-bottom: $xsite-_padding;
    @include breakpoint(tablet) {
      padding-left: $xsite-_padding--tablet;
      padding-right: $xsite-_padding--tablet;
      padding-bottom: $xsite-_padding--tablet;
    }
    @include breakpoint(desktop) {
      padding-left: $xsite-_padding--desktop;
      padding-right: $xsite-_padding--desktop;
      padding-bottom: $xsite-_padding--desktop;
    }
    @include breakpoint(wide) {
      padding-left: $xsite-_padding--wide;
      padding-right: $xsite-_padding--wide;
      padding-bottom: $xsite-_padding--wide;
    }
  }

  .panel__content {
    overflow-y: auto;
    min-height: 100vh;
  }

  .panel__header,
  .panel__footer {
    transition: all 150ms ease;
    @include breakpoint(tablet) {
      width: calc(100% - #{$sideBar-width});
    }
  }

  /*.panel__content {
    padding-top: calc(#{$panel__header-height} + 15px);
    padding-bottom: 15px;
    min-height: 100vh;
    .panel__content-form {
      margin: 0;
      max-width: none;
      padding: 0;
    }
  }*/

  .panel__header__inner,
  .panel__content__inner,
  .panel__footer__inner {
    margin: 0;
    max-width: none;
  }

  .panel__footer {
    left: initial;
  }

  .panel__content-centered {
    margin: 0;
    max-width: none;
    padding: 0;
  }

}


