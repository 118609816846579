.bo-dataSource-wrapper {
  margin-bottom: 20px;
}

.bo-dataSource {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 35px;
  text-align: center;
  background-color: #ddd;
  color: #999;
  font-size: 22px;
  font-weight: 500;
  transition: all 100ms linear;
  border: 1px solid #ccc;
  height: 100%;
  @include breakpoint(mobile) {
    padding: 60px 35px;
  }
  @include breakpoint(desktop) {
    padding: 80px 35px;
  }
  @include breakpoint(wide) {
    padding: 100px 35px;
  }
  @include on-event {
    text-decoration: none;
    background-color: $xcolor--accent;
    color: $xcolor--text-light;
    border-color: darken($xcolor--accent, 10%);
  }
}

.bo-dataSource--inactive {
  background-color: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  cursor: not-allowed;
  @include on-event {
    background-color: #fff;
    border: 1px dashed #bbb;
    color: #bbb;
  }
}

.bo-dataSource__title {
  line-height: 110%;
}
