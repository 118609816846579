// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.content-width {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// SPRITES
// -----------------------------------------------------------------------------
.xsprite-cms {
  background-image: $xsprite;
  background-image: $xsprite--svg;
  transition: 0.3s all;
}

.xsprite-cms--icon-arrow-left-big {
  width: 29px;
  height: 22px;
  background-position-x: -5px;

  &.xsprite-cms--white {
    background-position-y: -5px;
  }
  &.xsprite-cms--accent {
    background-position-y: -50px;
  }
}

.xsprite-cms--icon-arrow-down {
  width: 16px;
  height: 20px;
  background-position-x: -180px;

  &.xsprite-cms--white {
    background-position-y: -2px;
  }

  &.xsprite-cms--black {
    background-position-y: -80px;
  }
}

.xsprite-cms--icon-arrow-up {
  width: 16px;
  height: 20px;
  background-position-x: -210px;

  &.xsprite-cms--white {
    background-position-y: -2px;
  }

  &.xsprite-cms--black {
    background-position-y: -80px;
  }
}

.xsprite-cms--icon-cross {
  width: 12px;
  height: 12px;
  background-position-x: -351px;

  &.xsprite-cms--white {
    background-position-y: -5px;
  }

  &.xsprite-cms--black {
    background-position-y: -65px;
  }
}

.xsprite-cms--icon-cross-big {
  width: 22px;
  height: 22px;
  background-position-x: -310px;

  &.xsprite-cms--white {
    background-position-y: -5px;
  }

  &.xsprite-cms--accent {
    background-position-y: -40px;
  }
}

.xsprite-cms--icon-arrow-left {
  width: 20px;
  height: 16px;
  background-position-x: -240px;

  &.xsprite-cms--white {
    background-position-y: -4px;
  }

  &.xsprite-cms--black {
    background-position-y: -79px;
  }
}

.xsprite-cms--icon-arrow-right {
  width: 20px;
  height: 16px;
  background-position-x: -270px;

  &.xsprite-cms--white {
    background-position-y: -4px;
  }

  &.xsprite-cms--black {
    background-position-y: -79px;
  }
}

.xsprite-cms--icon-plus {
  width: 16px;
  height: 18px;
  background-position-x: -380px;

  &.xsprite-cms--black {
    background-position-y: -64px;
  }

  &.xsprite-cms--white {
    background-position-y: -4px;
  }
}

.xsprite-cms--icon-check {
  width: 15px;
  height: 10px;
  background-position-x: -411px;

  &.xsprite-cms--black {
    background-position-y: -65px;
  }

  &.xsprite-cms--white {
    background-position-y: -5px;
  }
}

.xsprite-cms--icon-caret-down {
  width: 12px;
  height: 8px;
  background-position-x: -180px;

  &.xsprite-cms--black {
    background-position-y: -190px;
  }
  &.xsprite-cms--white {
    background-position-y: -150px;
  }
}

.xsprite-cms--icon-loupe {
  width: 19px;
  height: 19px;
  background-position-x: -440px;

  &.xsprite-cms--white {
    background-position-y: -35px;
  }
}

.xsprite-cms--icon-trash {
  width: 18px;
  height: 18px;
  background-position-x: -440px;

  &.xsprite-cms--black {
    background-position-y: -160px;
  }
  &.xsprite-cms--grey {
    background-position-y: -130px;
  }
  &.xsprite-cms--white {
    background-position-y: -100px;
  }
}

.xsprite-cms--icon-global {
  width: 14px;
  height: 14px;
  background-position-x: -440px;
  background-position-y: -5px;
}