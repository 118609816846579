.data-list {
  list-style: none;
  padding: 0;
}

.data-list__item {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px $xcolor--light-grey;
  cursor: pointer;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
    z-index: -1;
    opacity: 0;
    transition: all 0.1s;
  }

  &:hover {
    color: $xcolor--white;
    &:after {
      opacity: 1;
    }
    .data-list__image-col {
      .image {
        border: solid 1px rgba($xcolor--bg, 0);
      }
    }
    .data-list__icon-col {
      .drag {
        transform: translateY(0);
      }
      .arrow {
        opacity: 1;
      }
    }
  }
}

.data-list__image-col {
  flex: 0 0 90px;
  padding: 20px 0 20px 20px;

  .image-container {
    position: relative;

    .image {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: solid 1px $xcolor--light-grey;
      background-size: cover;
    }
  }
}

.data-list__info-col {
  flex-grow: 1;
  padding: 20px 50px 20px 20px;
  min-width: 0; //https://css-tricks.com/flexbox-truncated-text/

  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 150%;
    -webkit-font-smoothing: antialiased;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
  }
}

.data-list__icon-col {
  flex: 0 0 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px 0 0;

  .drag {
    width: 12px;
    height: 8px;
    border-top: solid 2px $xcolor--light-grey;
    border-bottom: solid 2px $xcolor--light-grey;
    transform: translateX(34px);
    transition: all 0.2s;
  }

  .arrow {
    margin-left: 15px;
    opacity: 0;
    transition: all 0.2s;
  }
}