// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains all Sass mixins for the Core.
// -----------------------------------------------------------------------------

// GRID & STRUCTURE
// -----------------------------------------------------------------------------

/// Different options to display elements.
/// @param {string} $value ['block'] - Options are 'xi', 'xib', 'xif', 'xf' or none.
@mixin display($value: block) {
  position: relative;
  @if $value == 'xi' {
    display: inline;
  } @else if $value == 'xib' {
    display: inline-block;
    vertical-align: top;
  } @else if $value == 'xif' {
    display: inline-flex;
    vertical-align: top;
  } @else if $value == 'xf' {
    display: flex;
  } @else {
    display: block;
  }
}

/// Absolute elements.
/// @param {Bool} $full [false] - Whether or not to spread them fully.
@mixin absolute($full: false) {
  position: absolute;
  @if $full == true {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

/// Define column widths
/// @param {Integer} $width - The percentage of space you want it to take up.
@mixin flex-basis($width) {
  flex-basis: $width;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: $width;
  }
}

/// Define row heights
/// @param {Integer} $height - The percentage of space you want it to take up.
@mixin flex-height($height) {
  padding-top: #{$height};
}

/// Switch order for flexbox layouts.
/// @param {int} $int.
@mixin switch-order($int: 0) {
  order: $int;
}

/// Vertical align.
/// @param {String} $alignment [center] - Options are top, center or bottom.
@mixin vertical-align($alignment: center) {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  @if ($alignment == top) {
    justify-content: flex-start;
  } @else if ($alignment == bottom) {
    justify-content: flex-end;
  } @else {
    justify-content: center;
  }
}

// OTHER
// -----------------------------------------------------------------------------

/// Responsive manager
/// @param {String} $breakpoint - Breakpoint
/// @param {Bool} $max - Max-width or min-width
/// @requires $breakpoints or custom breakpoint (e.g. 760px)
/// @link http://sass-guidelin.es/#breakpoint-manager Sass Guidelines - Breakpoint Manager
@mixin breakpoint($breakpoint, $max: false) {
  $query: map-get($breakpoints, $breakpoint);

  @if not $query {
    @if not $max {
      @media screen and (min-width: inspect($breakpoint)) {
        @content;
      }
    } @else {
      @media screen and (max-width: inspect($breakpoint)) {
        @content;
      }
    }
  } @else {
    @if not $max {
      @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) {
        @content;
      }
    } @else {
      @media screen and (max-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) {
        @content;
      }
    }
  }
}

/// Hide on specific (break)point
/// @requires $breakpoints or custom breakpoint (e.g. 760px)
// @mixin hide-on($breakpoint, $display: block) {
//   $query: map-get($breakpoints, $breakpoint);
//
//   // display: $display;
//
//   @if not $query {
//     @media screen and (min-width: inspect($breakpoint)) {
//       display: none;
//     }
//   } @else {
//     @if not map-get-next($breakpoints, $breakpoint) {
//       @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) {
//         display: none;
//       }
//     } @else {
//       @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) and (max-width: map-get-next($breakpoints, $breakpoint)) {
//         display: none;
//       }
//     }
//   }
// }
//
// /// Show on specific (break)point.
// /// @requires $breakpoints or custom breakpoint (e.g. 760px)
// @mixin show-on($breakpoint, $display: block) {
//   $query: map-get($breakpoints, $breakpoint);
//
//   // display: none;
//
//   @if not $query {
//     @media screen and (min-width: inspect($breakpoint)) and (max-width: map-get-next($breakpoints, $breakpoint, 'none')) {
//       display: $display !important;
//     }
//   } @else {
//     @if not map-get-next($breakpoints, $breakpoint) {
//       @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) {
//         display: $display;
//       }
//     } @else {
//       @media screen and (min-width: #{if(type-of($query) == 'string', unquote($query), inspect($query))}) and (max-width: map-get-next($breakpoints, $breakpoint)) {
//         display: $display;
//       }
//     }
//   }
// }

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:focus {
      @content;
    }
  }
}

/// Clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/// Linear-gradient /w single color fallback
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient($direction, $color-stops);
}

/// Rounded borders.
/// @param {Value} $radius - Multiple values are allowed.
@mixin rounded($radius: 0) {
  border-radius: $radius;
  background-clip: padding-box;
}

/// Retina images. Use an image that's scaled x2.
/// @param {String} $image - The retina image that should be loaded.
/// @param {String} $width - Width of the new image.
/// @param {String} $height - Height of the new image.
/// @author Jason Zimdars
/// @link https://signalvnoise.com/posts/3271-easy-retina-ready-images-using-scss
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

/// CSS Columns.
/// @param {Value} $colWidth - The width of each column.
/// @param {Value} $colCount - The number of columns.
/// @param {Value} $colGap - The gap between columns.
/// @param {Value} $columnRuleColor - Color of the lines between columns.
/// @param {Value} $columnRuleStyle - Style of the lines between columns.
/// @param {Value} $columnRuleWidth - Width of the lines between columns.
@mixin columns($colWidth: 250px, $colCount: 0, $colGap: 50px, $columnRuleColor: #EEE, $columnRuleStyle: solid, $columnRuleWidth: 1px) {
  column-width: $colWidth;
  column-count: $colCount;
  column-gap: $colGap;
  column-rule-color: $columnRuleColor;
  column-rule-style: $columnRuleStyle;
  column-rule-width: $columnRuleWidth;
}
