// -----------------------------------------------------------------------------
// This file contains all styles related to the main element of the site/application.
// -----------------------------------------------------------------------------
.bo-main {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 150ms ease;
  @include breakpoint(tablet) {
    width: calc(100% - #{$sideBar-width});
    margin-left: $sideBar-width;
  }
}
