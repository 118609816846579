
.data-table {
  overflow-x: auto;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

tr {
  border-bottom: solid 1px $xcolor--white;
}

tbody tr {
  cursor: default;
  transition: 0.2s;
  &:hover {
    background-color: $xcolor--bg;
    color: $xcolor--text-light;
  }
}

th, td {
  border: none;
  text-align: left;
  padding: 8px;
}

tbody tr td {
  cursor: pointer;
}

tbody tr td.data-grid__column--select {
  cursor: default;
}

tbody tr td.data-grid__column--select, th.data-grid__column--select,
tbody tr td.data-grid__column--action, th.data-grid__column--action {
  width: 32px;
}