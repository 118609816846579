ul.pager {

  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 15px 0;
  font-size: 14px;
  line-height: 14px;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%; 
    top: 33px;
    border-bottom: solid 1px $xcolor--medium-grey;
  }

  li {
    margin: 0 10px;
    color: $xcolor--white;
  }

  li a {
    text-decoration: none;
    padding: 9px 11px;
    background-color: $xcolor--bg;
    color: $xcolor--text-light;
    height: 30px;
    display: inline-block;
    margin-right: 2px;
    cursor: pointer;

    &:hover, &:active {
      text-decoration: none;
    }
  }

  li a:hover, li.active a {
    background-color: $xcolor--accent;
  }

}