.data-table-big {
  position: relative;
  margin: 30px 0;
  color: $xcolor--white;
}

.data-table-big__header {
  margin-top: 55px;

  .col {
    display: flex;
    align-items: center;
  }
}

.data-table-big__addnew {
  margin-left: 15px;
}

.data-table-big {
  .form__field-label {
    top: 22px;
  }
}

.data-table-big__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;

  &:not(:first-child) {
    border-top: solid 1px $xcolor--medium-grey;
  }

  .click-columns {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: linear-gradient(150deg, $xcolor--accent-gradient-light 50%, $xcolor--accent-gradient-dark);
      z-index: -1;
      opacity: 0;
      transition: all 0.1s;
    }

    &:hover {
      color: $xcolor--white;
      &:after {
        opacity: 1;
      }
      .online {
        transform: translateY(0);
        color: $xcolor--white;
      }
      .arrow {
        opacity: 1;
      }
    }
  }

  .title {
    font-family: $xfontstack--helveticaneue;
    font-size: 22px;
    line-height: 150%;
    letter-spacing: 1px;
    color: $xcolor--white;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    font-weight: 200;
    letter-spacing: 1px;
    color: $xcolor--white;
  }

  .online {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    color: #45DD3B;
    text-transform: uppercase;
    transform: translateX(37px);
    transition: all 0.2s;
  }

  .arrow {
    margin-left: 15px;
    opacity: 0;
    transition: all 0.2s;
  }
}

.data-table-big__col {
  display: flex;

  &.checkbox-col {
    height: 100%;
  }

  &.text-col {
    flex-grow: 1;
    flex-direction: column;
    min-width: 0; //https://css-tricks.com/flexbox-truncated-text/
    padding: 15px;
  }

  &.status-col {
    flex: 0 0 100px;
    padding: 0 15px 0 0;
    align-items: center;
    justify-content: flex-end;
  }
}

.data-table-big__delete-offset {
  margin-left: -40px;
  transform: translateX(-50%);
}