// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains very basic styles for the Core.
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
  width: 100%;
}

// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
}

// Responsive images
img {
  max-width: 100%;
  @include display('xb');
}

// Remove Google logo from maps
a[href^="http://maps.google.com/maps"]{display:none !important}
a[href^="https://maps.google.com/maps"]{display:none !important}

// Hide the CMS and site-overlay by default.
.xcms, .xoverlay{
  display: none;
}
