.accordion__block {

}

.accordion__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 215px;
  padding: 75px 0 79px;
  background-color: $xcolor--bg;
  border-bottom: solid 1px $xcolor--medium-grey;
  color: $xcolor--text-light;
  cursor: pointer;
  transition: background 0.1s;

  .header-arrow-down {
    opacity: 1;
    transition: opacity 0.1s;
  }

  .header-arrow-up {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &:hover {
    background-color: $xcolor--dark-grey;
  }

  &.accordion__header--expand {
    height: 180px;
    padding: 75px 0 44px;
    background-color: $xcolor--white;
    border-bottom: solid 1px $xcolor--white;

    .accordion__title {
      color: $xcolor--text;
    }

    .accordion__caption, .header-arrow-down {
      opacity: 0;
    }

    .header-arrow-up {
      margin-top: -12px;
      opacity: 1;
    }
  }
}

.accordion__header-text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

}

.accordion__header-icon-col {
  flex: 0 0 52px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;

  .icon {
    width: 16px;
    height: 20px;
  }
}

.accordion__title {
  font-family: $xfontstack--helveticaneue;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: 1px;
  margin-left: -2px;
}

.accordion__caption {
  font-size: 18px;
  line-height: 100%;
  font-weight: 200;
  letter-spacing: 1px;
  height: auto;
  transition: 0.2s all;
  margin-top: 10px;
}

.accordion__content {
  background-color: $xcolor--white;
  max-height: 0;
  overflow: hidden;

  &.accordion__content--expand {
    max-height: 10000px; /*Whatever you like, as long as its more than the height of the content (on all screen sizes)*/
    border-bottom: solid 2px $xcolor--bg;
  }

}

.accordion__content-inner {
  padding-bottom: 75px;
}