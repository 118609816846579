// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar of the backoffice.
// -----------------------------------------------------------------------------

.bo-sideBar {
  width: $sideBar-width;
  height: 100%;
  position: fixed;
  transition: all 150ms ease;
  background: $xcolor--bg;
  color: #eee;
  overflow-y: auto;

  .sideBar__logoContainer {
    padding: 20px 10px;
    height: $panel__header-height;
    overflow: hidden;
  }

  .sideBar__logo {
    display: block;
    margin-bottom: 5px;
  }

  .sideBar__tagline {
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .sideBar__navigation {
    ul {
      list-style: none;
    }
  }

  // Main menu navigation
  .mainMenu {
    a {
      color: #eee;
    }
    .item {
      a {
        display: block;
        padding: 10px 15px;
        @include on-event {
          text-decoration: none;
        }
      }
    }
    .subMenu {
      background: #333;
    }
    .subMenu__link{
      color: #aaa;
      transition: all 50ms linear;
      @include on-event {
        color: lighten($xcolor--accent, 20%);
      }
      &.v-link-active{
        color: #eee;
        font-weight: 500;
      }
    }
  }

  .mainMenu__link {
    transition: all 50ms linear;
    @include on-event {
      color: lighten($xcolor--accent, 20%);
      background: #000;
    }
    .fa {
      margin-right: 5px;
    }
  }

  .mainMenu__item {
    &.v-link-active {
      background: $xcolor--accent;
      .mainMenu__link {
        position: relative;
        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          display: block;
          height: 0;
          width: 0;
          content: '';
          border: $mainMenu--active__triangleSize solid transparent;
          border-right-color: #fff;
          margin-top: -$mainMenu--active__triangleSize;
          pointer-events: none;
        }
        @include on-event {
          color: #fff;
          background: $xcolor--accent;
        }
      }
    }
  }

  //.mainMenu__item--active {
  //  background: $xcolor--accent;
  //  .mainMenu__link {
  //    position: relative;
  //    &:after {
  //      position: absolute;
  //      right: 0;
  //      top: 50%;
  //      display: block;
  //      height: 0;
  //      width: 0;
  //      content: '';
  //      border: $mainMenu--active__triangleSize solid transparent;
  //      border-right-color: #fff;
  //      margin-top: -$mainMenu--active__triangleSize;
  //      pointer-events: none;
  //    }
  //    @include on-event {
  //      color: #fff;
  //      background: $xcolor--accent;
  //    }
  //  }
  //}

  //.subMenu__item--active {
  //  .subMenu__link {
  //    color: #eee;
  //    font-weight: 500;
  //  }
  //}
}
