// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains helper styles for the Core.
// -----------------------------------------------------------------------------

// GENERAL
// -----------------------------------------------------------------------------

// Clear inner floats
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

//  Hide text while making it readable for screen readers
//  1. Needed in WebKit-based browsers because of an implementation bug;
//  See: https://code.google.com/p/chromium/issues/detail?id=457146
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Background Covers
.xbgcov {background:no-repeat; background-size: cover;}
.xbgcon {background:no-repeat; background-size: contain;}

// Hide/show on specific breakpoints
// Todo: needs more work
// @each $key, $value in $breakpoints {
//   .show-on-#{$key} {
//     @include show-on($key);
//   }
//   .hide-on-#{$key} {
//     @include hide-on($key);
//   }
// }

// SPRITES
// -----------------------------------------------------------------------------
.xsprite{
  background-image: $xsprite;
  background-image: $xsprite--svg;
}

// SITE OVERLAY
// -----------------------------------------------------------------------------
// Hides the overflow on the site, while editing in the CMS.
.js-overflow-hidden {
  overflow: hidden;
}

.xoverlay{
  position: fixed;
  opacity: 1;
  .xmobile_nav{
    overflow-y: scroll;
  }
  &.black{
    background-color: black;
    opacity: 0;
  }
  &.white{
    background-color: white;
    opacity: 0;
  }
}

// ICONS
// -----------------------------------------------------------------------------
.xicon{
  transition-duration: 200ms;
  .xicon--standard, .xicon--hover{
    left: 0;
    top: 0;
  }
  .xicon--standard{
    opacity: 1;
  }
  .xicon--hover{
    opacity: 0;
  }
  &:hover{
    .xicon--standard{
      opacity: 0;
    }
    .xicon--hover{
      opacity: 1;
    }
  }
}

// JUSTIFIED TEXT
// -----------------------------------------------------------------------------
.xjustify{
  text-align: justify;
  text-justify: distribute-all-lines;
  vertical-align: center;
  li{
    display: inline-block;
    *display: inline;
    zoom: 1;
  }
  .xstretch {
    width: 100%;
    height: 0;
    display: inline-block;
    font-size: 0;
    line-height: 0;
  }
}

// MAKE RELATIVE
// -----------------------------------------------------------------------------
.xmake-relative{
  @include breakpoint(desktop, true) {
    padding: 0;
    .xg_content, .xabf{
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: 100%;
    }
    .xabf.ximg{
      padding-top: 50%;
    }
  }
}

// XIMG
// -----------------------------------------------------------------------------
.ximg-item {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.ximg-img {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  img {
    display: block;
    width: 100%;
  }
}
