.login {

  width: 100%;
  height: 100%;
  position: fixed;
  background: $xcolor--bg;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    flex: 0 1 500px;
  }

  .form__logo {
    position: absolute;
    top: 40px;
    left: 40px;
  }

  .form__field-label,
  .form__field-input {
    padding-left: 10px;
    padding-right: 10px;
    //border-radius: 3px;
  }

  .form__field-input {
    box-shadow: 0 0 0px 1000px $xcolor--bg inset;
    color: $xcolor--text-light;
    -webkit-text-fill-color: $xcolor--text-light !important;
    border-bottom: solid 1px $xcolor--medium-faded-grey;

    &:focus {
      border-bottom: solid 1px $xcolor--text-light;
      /*box-shadow: 0 0 0px 1000px $xcolor--bg inset, 0 1px 0 0 $xcolor--text-light;*/
    }

    &::selection {
      background: $xcolor--white;
      color: $xcolor--bg;
    }
  }

  .form__field-label {
    transform: translateX(-200px);
  }

  .form__footer {
    color: $xcolor--text-light;
  }

}