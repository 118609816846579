// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

#boness {
  ::selection {
    background-color: $xcolor--bg;
    color: #FFF;
  }

  a {
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .front-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($xcolor--bg, 0.7);
  }
}

// Add padding for top-menu CMS.
/*
body.logged-in {
  padding-top: 36px;
}*/
