// -----------------------------------------------------------------------------
//    _____    __ __    ____   _   __   _   __
//   / ___/   / //_/   /  _/  / | / /  / | / /
//   \__ \   / ,<      / /   /  |/ /  /  |/ /
//  ___/ /  / /| |   _/ /   / /|  /  / /|  /
// /____/  /_/ |_|  /___/  /_/ |_/  /_/ |_/
//
// -----------------------------------------------------------------------------
// This file contains styles concerning the grid structure of the Core.
// -----------------------------------------------------------------------------

// Calculations
$grid-width: ($columns * $column-width) + (($columns ) * $gutter-width);

// Percentage function
@function percentage($size, $width) {
  @return $size / $width * 100%;
}

// Calculate column width
@function column($value) {
  $width: $value * $column-width + ($value ) * $gutter-width;
  @return percentage($width, $grid-width);
}

/// Mixin to define the column settings for all breakpoints.
/// The parameter is to pass onto the column mixin.
@mixin column-content($i) {
  flex-basis: column($i);
  max-width: column($i);
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: column($i);
  }
}

/// Mixin gutter-output
/// If $fixed-gutter-size is set to true this will output
/// the $gutter-width as a fixed value and not percentage
@mixin gutter-output {
  @if $fixed-gutter-size == true {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
  }
  @else {
    padding-left: percentage($gutter-width, $grid-width) / 2;
    padding-right: percentage($gutter-width, $grid-width) / 2;
  }
}

// Wrapper class for .col classes
.row {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

// Helper classes
.row--full {
  margin-left: -$gutter-width / 2;
  margin-right: -$gutter-width / 2;
}
.row--auto {
  .col {
    flex-grow: 1;
  }
}

.row--start {
  justify-content: flex-start;
  .col {
    flex-grow: 0;
  }
}

.row--center {
  justify-content: center;
  .col {
    flex-grow: 0;
  }
}

.row--end {
  justify-content: flex-end;
  // default columns class required to be altered
  .col {
    flex-grow: 0;
  }
}

.row--top {
  align-items: flex-start;
}

.row--middle {
  align-items: center;
}

.row--baseline {
  align-items: baseline;
}

.row--wrap {
  flex-wrap: wrap;
}

.row--row {
  flex-direction: row;
}

.row--bottom {
  align-items: flex-end;
  .col {
    flex-grow: 1;
  }
}

.row--stretch {
  align-items: stretch;
}


.row--around {
  justify-content: space-around;
  // default columns class required to be altered
  .col {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.row--between {
  justify-content: space-between;
  // default columns class required to be altered
  .col {
    flex-grow: 0;
    flex-shrink: 1;
  }
}

.row--reverse {
  flex-direction: row-reverse;
}

// Default columns class - auto flex
.col {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  max-width: 100%;
  position: relative;
  @include gutter-output;
}
.col--first {
  order: -1;
}
.col--last {
  order: 1;
}
.col--full {
  padding-left: 0;
  padding-right: 0;
}

// Cycles through $columns and calculates offsets
@for $i from 1 through $columns {
  .col__offset--#{$i} {
    margin-left: column($i);
  }
}

// Cycles through $columns and calculates widths
@for $i from 1 through $columns {
  .col--#{$i} {
    @include column-content($i);
  }
}

// Cycles through $columns and
// generates column classes for different breakpoints
@include breakpoint(mobile) {
  @for $i from 1 through $columns {
    .col__sm--#{$i} {
      @include column-content($i);
    }
  }
}
@include breakpoint(tablet) {
  @for $i from 1 through $columns {
    .col__md--#{$i} {
      @include column-content($i);
    }
  }
}
@include breakpoint(desktop) {
  @for $i from 1 through $columns {
    .col__lg--#{$i} {
      @include column-content($i);
    }
  }
}
@include breakpoint(wide) {
  @for $i from 1 through $columns {
    .col__xl--#{$i} {
      @include column-content($i);
    }
  }
}
