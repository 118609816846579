.page-tree {

  list-style: none;

  &.page-tree--nested {
    margin-left: 30px;
  }

  .page-tree__item {

    position: relative;

    > a, > a:active, > a:visited, > div {
      padding: 10px;
      border-bottom: solid 1px #F8F8F8;
    }

    a {
      color: $xcolor--text;
      text-decoration: none;
    }

    @for $i from 1 to 10 {
      &.page-tree__item--level#{$i} {
        margin-left: 30px * $i;
      }
    }

  }

  .page-tree__item-content {
    cursor: default;
    transition: 0.1s;

    &:hover, &.page-tree__item-content--selected {
      background-color: $xcolor--bg;
      color: $xcolor--text-light;

      a {
        color: $xcolor--text-light;
        text-decoration: underline;
      }
    }

    &.page-tree__item--readonly, .page-tree__item--readonly:hover {
      opacity: 0.6;
      cursor: not-allowed;
      background-color: transparent;
      color: $xcolor--text;
    }
  }

  .page-tree__item-text {
    opacity: 0.8;
  }

}
